import { BASE_URL, authorizedRoutesHeaders } from "./config";

interface GetVehiclesByConnectionIdPayload {
    token: string;
    connection_id: string;
}

// get vehicles by connection_id
export const getVehiclesByConnectionId = async (payload: GetVehiclesByConnectionIdPayload) => {
    const r = await fetch(
        `${BASE_URL}/vehicles?connectionId=${payload.connection_id}`,
        {
            method: 'GET',
            headers: authorizedRoutesHeaders(payload.token),
        },
    ).catch((e) => e.response);
    if (!r?.ok) return [];
    return r.json();
};

interface UpdateVehicleByVehicleIdPayload {
    token: string;
    connection_id: string;
    vehicle_id: string;
    has_coaching: boolean;
}

// update vehicles by connection_id
export const updateVehicleByVehicleId = async (payload: UpdateVehicleByVehicleIdPayload) => {
    const r = await fetch(
        `${BASE_URL}/vehicles/${payload.vehicle_id}`,
        {
            method: 'PUT',
            headers: authorizedRoutesHeaders(payload.token),
            body: JSON.stringify({
                ConnectionId: payload.connection_id,
                HasCoaching: payload.has_coaching,
            }),
        },
    ).catch((e) => e.response);
    return !!r?.ok;
};

interface GetVehicleByIdPayload {
    token: string;
    id: string;
}

//get vehicle by id
export const getVehicleById = async (payload: GetVehicleByIdPayload) => {
    const r = await fetch(
        `${BASE_URL}/vehicles/${payload.id}`,
        {
            method: 'GET',
            headers: authorizedRoutesHeaders(payload.token),
        },
    ).catch((e) => e.response);
    if (!r?.ok) return;
    return r.json();
};

export default {
    getVehiclesByConnectionId,
    updateVehicleByVehicleId,
    getVehicleById
};