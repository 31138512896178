import { useAuth0 } from '@auth0/auth0-vue';
import {
    users as usrs,
    providers as pvds,
    connections as cons,
    vehicles as vhlcs,
    drivers as drvrs,
} from '$api';
import { createStore } from 'vuex';
import _ from 'lodash';

const state = {
    auth0: null,
    route: {
        from: null,
        to: null,
    },
    token: null,
    user: null,
    groups: [],
    providers: [],
    connections: [],
    vehicles: [],
    drivers: [],
    incidents: [
        {
            "id": 1,
            "name": "follow_too_close",
            "description": "follow too close",
            "extended": false
        },
        {
            "id": 2,
            "name": "late_start",
            "description": "late start",
            "extended": false
        },
        {
            "id": 3,
            "name": "late_brake",
            "description": "late brake",
            "extended": false
        },
        {
            "id": 4,
            "name": "lane_change_no_signal",
            "description": "lane violation",
            "extended": false
        },
        {
            "id": 5,
            "name": "hard_on_brake",
            "description": "hard on brake",
            "extended": false
        },
        {
            "id": 6,
            "name": "hard_on_gas",
            "description": "hard on gas",
            "extended": false
        },
        {
            "id": 7,
            "name": "harsh_motion",
            "description": "harsh motion",
            "extended": false
        },
        {
            "id": 8,
            "name": "red_pass",
            "description": "red light violation",
            "extended": false
        },
        {
            "id": 9,
            "name": "stop_sign_pass",
            "description": "stop sign violation",
            "extended": false
        },
        {
            "id": 10,
            "name": "distracted_driving",
            "description": "distracted driving",
            "extended": false
        },
        {
            "id": 12,
            "name": "zig_zagging",
            "description": "zig zagging zones",
            "extended": false
        },
        {
            "id": 13,
            "name": "hard_turning",
            "description": "hard turning",
            "extended": false
        },
        {
            "id": 15,
            "name": "red_flag",
            "description": "red-flag",
            "extended": false
        },
        {
            "id": 16,
            "name": "speeding",
            "description": "speeding",
            "extended": true
        },
        {
            "id": 17,
            "name": "no_seatbelt",
            "description": "no seatbelt",
            "extended": true
        },
        {
            "id": 18,
            "name": "accident_no_fault",
            "description": "accident no fault",
            "extended": false
        },
        {
            "id": 14,
            "name": "accident",
            "description": "accident",
            "extended": false
        },
        {
            "id": 19,
            "name": "button_pressed",
            "description": "button pressed",
            "extended": false
        }
    ],
};
const getters = {
    groupsById(state: any) {
        return Object.fromEntries(state.groups.map((i: any) => [i._id, i]));
    },
    providersById(state: any) {
        return Object.fromEntries(state.providers.map((i: any) => [i.id, i]));
    },
    connectionsById(state: any) {
        return Object.fromEntries(state.connections.map((i: any) => [i.id, i]));
    },
    vehiclesById(state: any) {
        return Object.fromEntries(state.vehicles.map((i: any) => [i.id, i]));
    },
    vehiclesByConnectionId(state: any) {
        return _.groupBy(state.vehicles, 'connectionId');
    },
    driversById(state: any) {
        return Object.fromEntries(state.drivers.map((i: any) => [i.id, i]));
    },
    driversByConnectionId(state: any) {
        return _.groupBy(state.drivers, 'connectionId');
    },
    allIncidentsByName(state: any) {
        return Object.fromEntries(state.incidents.map((i: any) => [i.name, i]));
    },
    incidents(state: any) {
        return state.incidents.filter((i: any) => !i.extended);
    },
    incidentsByName(_state: any, getters: any) {
        return Object.fromEntries(getters.incidents.map((i: any) => [i.name, i]));
    },
    extendedIncidents(state: any) {
        return state.incidents.filter((i: any) => i.extended);
    },
    extendedIncidentsByName(_state: any, getters: any) {
        return Object.fromEntries(getters.extendedIncidents.map((i: any) => [i.name, i]));
    },
};
const mutations = {
    setToken(state: any, v: any) {
        state.token = v;
    },
    setUser(state: any, v: any) {
        state.user = v;
    },
    setGroups(state: any, v: any) {
        state.groups = v;
    },
    setProviders(state: any, v: any) {
        state.providers = v;
    },
    setConnections(state: any, v: any) {
        state.connections = v;
    },
    setVehicles(state: any, v: any) {
        state.vehicles = v;
    },
    setDrivers(state: any, v: any) {
        state.drivers = v;
    },
};
const actions = {
    async getProviders(context: any) {
        const providers = await pvds.getProviders({ token: context.state.token });
        context.commit('setProviders', providers);
    },
    async getConnections(context: any) {
        const connections = await cons.getConnectionsByClientId({
            token: store.state.token,
            client_id: store.state.user?.clientId,
        });
        const vehicles = await Promise.all(
            connections.map((i: any) => vhlcs.getVehiclesByConnectionId({
                token: store.state.token,
                connection_id: i.id,
            })),
        );
        const drivers = await Promise.all(
            connections.map((i: any) => drvrs.getDriversByConnectionId({
                token: store.state.token,
                connection_id: i.id,
            })),
        );
        context.commit('setConnections', connections);
        context.commit('setVehicles', vehicles.reduce((a: any, i: any) => {
            a = [
                ...a,
                ...i,
            ];
            return a;
        }, []));
        context.commit('setDrivers', drivers.reduce((a: any, i: any) => {
            a = [
                ...a,
                ...i,
            ];
            return a;
        }, []));
    },
    async checkAuthentication(context: any) {
        context.state.auth0 = useAuth0();
        try {
            const token = await context.state.auth0.getAccessTokenSilently();
            context.commit('setToken', token);
        } catch (e) {
            if (context.state.route.to.path.match(/\/(integration\/invite)/i)) {
                await context.dispatch('getProviders');
                return;
            }
            context.state.auth0.loginWithRedirect();
        }
    },
};

const store = createStore({ state, getters, mutations, actions });

store.subscribe(async (mutation: any, state: any) => {
    // get user information after we got token
    if (
        mutation.type === 'setToken'
        && mutation.payload
        && !state.user
        && !state.providers?.length) {
        const token = mutation.payload;
        const [user, groups] = await Promise.all([
            usrs.getMe({ token }),
            usrs.getGroupsCached({ token }),
        ]);
        store.commit('setUser', {
            ...user,
            ...state.auth0.user,
        });
        store.commit('setGroups', groups);
        if (store.state.providers.length && store.state.connections.length) return;
        await Promise.all([
            await store.dispatch('getProviders'),
            await store.dispatch('getConnections'),
        ]);
    }
});

export default store;