import { createAuth0 } from '@auth0/auth0-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
  disableRightClick,
} from '$directives';

import ForesightUIComponents from 'foresight-ui-components';
import {createApp} from 'vue/dist/vue.esm-bundler';

import 'foresight-ui-components/dist/style.css';
import './style.css';

const app = createApp(App);

app.use(
  createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN ?? 'foresight-aggregator-stg.us.auth0.com',
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID ?? 'DUym8l3fb3EPo9BHOh12S9ib2ddfbMAS',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE ?? 'https://stg.api.4si8analytics.com/',
    }
  })
);

app.use(store)
  .use(router)
  .use(ForesightUIComponents);

app.directive('disable-right-click', disableRightClick);

app.mount('#app');
