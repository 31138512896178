import { BASE_URL, authorizedRoutesHeaders } from "./config";

interface GetConnectionsByClientIdPayload {
    token: string;
    client_id: string;
}
// get connections by client_id
export const getConnectionsByClientId = async (payload: GetConnectionsByClientIdPayload) => {
    const r = await fetch(`${BASE_URL}/connections?clientId=${payload.client_id}`, {
        method: 'GET',
        headers: authorizedRoutesHeaders(payload.token),
    }).catch((e) => e.response);
	if (!r?.ok) return [];
	return r.json();
};

interface PostConnectionByClientIdPayload {
    token: string;
    client_id: string;
    name: string;
    provider_id: string;
    api_key?: string;
    username?: string;
    password?: string;
    database?: string;
}
// add connection by client_id
export const postConnectionByClientId = async (payload: PostConnectionByClientIdPayload) => {
    const r = await fetch(`${BASE_URL}/connections?clientId=${payload.client_id}`, {
        method: 'POST',
        headers: authorizedRoutesHeaders(payload.token),
        body: JSON.stringify({
            Name: payload.name,
            ProviderId: payload.provider_id,
            ...payload.api_key && { ApiToken: payload.api_key },
            ...payload.username && { Username: payload.username },
            ...payload.password && { Password: payload.password },
            ...payload.database && { Database: payload.database },
        }),
    }).catch((e) => e.response);
	return r?.ok;
};

interface DeleteConnectionsByConnectionIdPayload {
    token: string;
    connection_id: string;
}
// delete connection by connection_id
export const deleteConnectionByConnectionId = async (payload: DeleteConnectionsByConnectionIdPayload) => {
    const r = await fetch(`${BASE_URL}/connections/${payload.connection_id}`, {
        method: 'DELETE',
        headers: authorizedRoutesHeaders(payload.token),
    }).catch((e) => e.response);
	return r?.ok;
};

export default {
    getConnectionsByClientId,
    postConnectionByClientId,
    deleteConnectionByConnectionId,
};