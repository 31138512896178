import { BASE_URL } from "./config";

// get telematics service providers list
export const getProviders = async () => {
    const r = await fetch(`${BASE_URL}/providers`, {
        method: 'GET',
    }).catch((e) => e.response);
	if (!r?.ok) return [];
	return r.json();
}

export default {
    getProviders,
};