import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

import RegularRoutes from './regular.ts';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...RegularRoutes,
        // {
        //     path: '/:pathMatch(.*)*',
        //     component: () => import('../views/404/page.vue'),
        // },
    ],
});

router.beforeEach((to, from, next) => {
    store.state.route = {
        to,
        from,
    };
    next();
});

export default router;