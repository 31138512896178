export const BASE_URL = import.meta.env.VITE_BASE_URL ?? 'https://stg.api.4si8analytics.com';

export const authorizedRoutesHeaders = (token: string) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return headers;
};

export default {
    BASE_URL,
    authorizedRoutesHeaders,
};