import { BASE_URL, authorizedRoutesHeaders } from "./config";
import { addDays } from "date-fns";

interface GetGroupsPayload {
    token: string;
}
// get user groups
export const getGroups = async (payload: GetGroupsPayload) => {
    const r = await fetch(`${BASE_URL}/users/groups`, {
        method: 'GET',
        headers: authorizedRoutesHeaders(payload.token),
    }).catch((e) => e.response);
	if (!r?.ok) return [];
	return r.json();
};

// get user groups cached
export const getGroupsCached = async (payload: GetGroupsPayload): Promise<any> => {
    let cachedGroups: any = localStorage.getItem('groups');
    if (!cachedGroups) {
        const r = await getGroups(payload);
        localStorage.setItem('groups', JSON.stringify({
            value: r,
            expires: addDays(new Date(), 1),
        }));
        return r;
    }
    cachedGroups = JSON.parse(cachedGroups);
    if (new Date(cachedGroups?.expires) < new Date()) {
        localStorage.removeItem('groups');
        return getGroupsCached(payload);
    }
    return cachedGroups.value;
};

interface GetMePayload {
    token: string;
}
// get user info
export const getMe = async (payload: GetMePayload) => {
    const r = await fetch(`${BASE_URL}/users/me`, {
        method: 'GET',
        headers: authorizedRoutesHeaders(payload.token),
    }).catch((e) => e.response);
	if (!r?.ok) return;
	return r.json();
};

interface PostInvite {
    token: string;
    email: string;
    client_id: string;
    group_id: string;
}
// post user invite
export const postInvite = async (payload: PostInvite) => {
    const r = await fetch(`${BASE_URL}/users/invite?clientId=${payload.client_id}`, {
        method: 'POST',
        headers: authorizedRoutesHeaders(payload.token),
        body: JSON.stringify({
            Email: payload.email,
            GroupId: payload.group_id,
        }),
    }).catch((e) => e.response);
	return (r?.status === 204);
};

interface PostInviteResend {
    token: string;
    email: string;
    client_id: string;
}
// post user invite resend
export const postInviteResend = async (payload: PostInviteResend) => {
    const r = await fetch(`${BASE_URL}/users/invite/resend?clientId=${payload.client_id}`, {
        method: 'POST',
        headers: authorizedRoutesHeaders(payload.token),
        body: JSON.stringify({
            Email: payload.email,
        }),
    }).catch((e) => e.response);
	return (r?.status === 204);
};

interface RevokeInvite {
    token: string;
    email: string;
    client_id: string;
}
// post user invite resend
export const revokeInvite = async (payload: RevokeInvite) => {
    const r = await fetch(`${BASE_URL}/users/invite/revoke?clientId=${payload.client_id}`, {
        method: 'DELETE',
        headers: authorizedRoutesHeaders(payload.token),
        body: JSON.stringify({
            Email: payload.email,
        }),
    }).catch((e) => e.response);
	return (r?.status === 204);
};

interface DeleteUser {
    token: string;
    user_id: string;
    client_id: string;
}
// post user invite resend
export const deleteUser = async (payload: DeleteUser) => {
    const r = await fetch(`${BASE_URL}/users/${payload.user_id}?clientId=${payload.client_id}`, {
        method: 'DELETE',
        headers: authorizedRoutesHeaders(payload.token),
    }).catch((e) => e.response);
	return (r?.status === 204);
};

interface GetUsersByClientIdPayload {
    token: string;
    client_id: string;
}
// get users by client_id
export const getUsersByClientId = async (payload: GetUsersByClientIdPayload) => {
    const r = await fetch(`${BASE_URL}/users?clientId=${payload.client_id}`, {
        method: 'GET',
        headers: authorizedRoutesHeaders(payload.token),
    }).catch((e) => e.response);
	if (!r?.ok) return [];
	return r.json();
};

export default {
    getGroups,
    getGroupsCached,
    getMe,
    postInvite,
    postInviteResend,
    revokeInvite,
    deleteUser,
    getUsersByClientId,
};