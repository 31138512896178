import { DirectiveBinding } from 'vue';

const disableRightClick = {
  beforeMount(el: any, binding: DirectiveBinding) {
    el.__handleRightClick__ = (event: MouseEvent) => {
      event.preventDefault();
    };
    el.addEventListener('contextmenu', el.__handleRightClick__);
  },
  unmounted(el: any) {
    if (el.__handleRightClick__) {
      el.removeEventListener('contextmenu', el.__handleRightClick__);
      delete el.__handleRightClick__;
    }
  }
};

export default disableRightClick;
