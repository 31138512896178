<template>
  <Header
    v-if="!$route.path.match(/\/(integration)/i)
      && $store.state.user"
  />
  <router-view
    v-if="loaded"
    :key="$route.path"
  />
  <div v-else class="flex flex-col items-center justify-center h-screen p-4">
    <fs-progress />
  </div>
</template>

<script>
import Header from './header.vue';
import { utils } from '$lib';

export default {
  name: 'AppView',
  components: {
    Header,
  },
  computed: {
    loaded() {
      if (this.$route.path.match(/\/(integration\/invite)/i)) {
        return !!this.$store.state.providers?.length;
      }
      return !!this.$store.state.user && !!this.$store.state.providers?.length;
    },
  },
  async mounted() {
    await this.$store.dispatch('checkAuthentication');
  },
  async created() {
    utils.toggleDarkMode(localStorage.theme ?? 'system-preference');
  },
};
</script>

<style>
body {
  @apply bg-slate-100 dark:!bg-slate-800;
}
</style>