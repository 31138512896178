<template>
    <fs-wrapper class="flex-row !p-0 items-center min-h-[4rem] rounded-none dark:!bg-zinc-950 dark:!bg-opacity-20">
        <router-link to="/">
          <div
              class="
                  bg-[center_bottom] bg-no-repeat h-11 p-0 min-w-[100px] mr-8
                  bg-[url(https://cdn.xmatik.ca/portal/img/logo/fs-black.svg)]
                  dark:bg-[url('https://cdn.xmatik.ca/portal/img/logo/fs-white.svg')]
              "
          />
        </router-link>
        <!-- desktop menu -->
        <div class="hidden lg:flex flex-row items-center flex-1">
          <fs-dropdown v-for="(mainMenuItem, k) in mainMenu" v-model="mainMenuItem.show">
            <template #trigger>
              <fs-button v-if="mainMenuItem.visible"
                  :type="parent === mainMenuItem.key ? '' : 'text'"
                  :color="parent === mainMenuItem.key ? 'blue' : 'black'"
                  icon="expand_more"
                  icon-position="right"
                  class="mr-2"
                  @click="mainMenuItem.show = true"
              >
                  {{ mainMenuItem.name }}
              </fs-button>
            </template>

            <template #default>
              <div
                v-for="item, k in menuItems
                  .filter((i) => i.props?.parent === mainMenuItem.key)
                  .filter((i) => !(i.props?.excludeRoles ?? []).includes($store.state.user?.role))"
                :key="k"
                class="flex flex-col w-full mb-3 last:mb-0"
              >
                <fs-button
                  v-if="!item.children?.length || item.props?.hide_children"
                  :router-link="true"
                  :type="currentPage === item.name ? '' : 'text'"
                  :color="currentPage === item.name ? 'blue' : 'black'"
                  :to="item.path"
                  :icon="!item.props?.customIcon ? item.props?.icon : ''"
                  class="justify-start"
                >
                    <i v-if="item.props?.customIcon" :class="`fs-${item.props?.icon} text-2xl mr-2`" />
                    {{ item.name }}
                </fs-button>
                <template v-else>
                  <fs-button
                    v-for="child, kk in item.children"
                    :key="kk"
                    :router-link="true"
                    :type="currentPage === child.name ? '' : 'text'"
                    :color="currentPage === child.name ? 'blue' : 'black'"
                    :to="child.props?.disabled ? '' :`${item.path}/${child.path}`"
                    :icon="!child.props?.customIcon ? child.props?.icon : ''"
                    class="justify-start mb-4 last:mb-0"
                    :class="{'!bg-zinc-400 dark:!bg-zinc-600 dark:!text-white': child.props?.disabled}"
                    :disabled="child.props?.disabled"
                  >
                      <i v-if="child.props?.customIcon" :class="`fs-${child.props?.icon} text-3xl mr-4`" />
                      {{ child.name }}
                  </fs-button>
                </template>
              </div>
            </template>
          </fs-dropdown>

          <div class="flex-1" />

          <fs-button
              :router-link="true"
              :type="$route.path.match(/^\/profile/i) ? '' : 'text'"
              :color="$route.path.match(/^\/profile/i) ? 'blue' : 'black'"
              to="/profile/preferences"
              icon="person"
              class="mr-4"
          />

          <!-- logout -->
          <fs-button type="text" color="black" icon="logout" class="mr-2" @click="logout" />
        </div>

        <!-- mobile menu -->
        <div class="flex lg:hidden flex-row flex-1">
            <div class="flex-1" />
            <!-- menu trigger -->
            <fs-button
                type="text"
                color="black"
                icon="menu"
                class="mr-2"
                @click="() => {
                    show = !show;
                    if (show) {
                        disableScroll();
                    } else {
                        enableScroll();
                    }
                }"
            />
        </div>
        <!-- menu content -->
        <fs-wrapper
            size="x-small"
            class="
                lg:hidden fixed left-0 right-0 top-16 bottom-0 z-[999999]
                backdrop-blur-lg transition-all ease-in-out origin-top mt-2
            "
            :class="{
                'scale-y-100 opacity-1': show,
                'scale-y-0 opacity-0': !show,
            }"
        >
          <fs-dropdown v-for="(mainMenuItem, k) in mainMenu" v-model="mainMenuItem.show" content-class="w-[calc(100%-1rem)] lg:w-auto">
            <template #trigger>
              <fs-button
                :type="parent === mainMenuItem.key ? '' : 'text'"
                :color="parent === mainMenuItem.key ? 'blue' : 'black'"
                icon="expand_more"
                icon-position="right"
                class="w-full mb-4"
                @click="mainMenuItem.show = true"
              >
                  {{ mainMenuItem.name }}
              </fs-button>
            </template>

            <template #default>
              <div
                v-for="item, k in menuItems
                  .filter((i) => i.props?.parent === mainMenuItem.key)
                  .filter((i) => !(i.props?.excludeRoles ?? []).includes($store.state.user?.role))"
                :key="k"
                class="flex flex-col w-full mb-3 last:mb-0"
              >
                <fs-button
                  v-if="!item.children?.length || item.props?.hide_children"
                  :router-link="true"
                  :type="currentPage === item.name ? '' : 'text'"
                  :color="currentPage === item.name ? 'blue' : 'black'"
                  :to="item.path"
                  :icon="!item.props?.customIcon ? item.props?.icon : ''"
                  class="justify-start"
                >
                    <i v-if="item.props?.customIcon" :class="`fs-${item.props?.icon} text-2xl mb-2`" />
                    {{ item.name }}
                </fs-button>
                <template v-else>
                  <fs-button
                    v-for="child, kk in item.children"
                    :router-link="true"
                    :key="kk"
                    :type="currentPage === child.name ? '' : 'text'"
                    :color="currentPage === child.name ? 'blue' : 'black'"
                    :to="child.props?.disabled ? '' :`${item.path}/${child.path}`"
                    :icon="!child.props?.customIcon ? child.props?.icon : ''"
                    class="justify-start mb-4 last:mb-0"
                    :class="{'!bg-zinc-400 dark:!bg-zinc-600 dark:!text-white': child.props?.disabled}"
                    :disabled="child.props?.disabled"
                  >
                      <i v-if="child.props?.customIcon" :class="`fs-${child.props?.icon} text-2xl mb-2`" />
                      {{ child.name }}
                  </fs-button>
                </template>
              </div>
            </template>
          </fs-dropdown>

            <fs-button
                :router-link="true"
                :type="$route.path.match(/^\/profile/i) ? '' : 'text'"
                :color="$route.path.match(/^\/profile/i) ? 'blue' : 'black'"
                to="/profile/preferences"
                icon="person"
                class="mb-4"
            >
              Profile
            </fs-button>

            <!-- logout -->
            <fs-button
                color="gray"
                icon="logout"
                @click="() => {
                    show = false;
                    logout();
                }"
            >
                Logout
            </fs-button>
        </fs-wrapper>
    </fs-wrapper>
</template>

<script>
const keys = {37: 1, 38: 1, 39: 1, 40: 1};
const preventDefault = (e) => e.preventDefault();
const preventDefaultForScrollKeys = (e) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};
const wheelOpt = {passive: false};
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

export default {
  data() {
    return {
      show: false,
      mainMenu: [
        {
          key: 'analytics',
          name: 'Analytics',
          show: false,
          visible: true,
        },
        {
          key: 'view',
          name: 'View',
          show: false,
          visible: true,
        },
        {
          key: 'team',
          name: 'Team',
          show: false,
          visible: true,
        },
        {
          key: 'coaching',
          name: 'Coaching',
          show: false,
          visible: true,
        },
        {
          key: 'data-engine',
          name: 'Data Engine',
          show: false,
          visible: true,
        },
        {
          key: 'admin',
          name: '💫 Admin',
          show: false,
          visible: this.$store.state.user?.user_roles?.includes('admin'),
        },
      ],
    };
  },
  computed: {
    parent() {
      const route = (this.$route.matched ?? []).find((i) => i.props?.default?.parent);
      const v = route?.props?.default?.parent;
      return v;
    },
    currentPage() {
      return this.$route.name ?? '';
    },
    menuItems() {
      return this.$router.options.routes
        .map((i) => ({
          name: i.name,
          path: i.path,
          props: i.props,
          children: i.children,
        }))
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.show = false;
      },
      deep: true,
    }
  },
  methods: {
    logout() {
      this.$auth0.logout({ 
          logoutParams: { 
              returnTo: window.location.origin,
          },
      });
    },
    disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false);
      window.addEventListener(wheelEvent, preventDefault, wheelOpt);
      window.addEventListener('touchmove', preventDefault, wheelOpt);
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    },
    enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    },
  },
};
</script>
