import { BASE_URL, authorizedRoutesHeaders } from "./config";

interface GetDriversByConnectionIdPayload {
    token: string;
    connection_id: string;
}
interface GetDriverByIdPayload {
    token: string;
    id: string;
}
// get drivers by connection_id
export const getDriversByConnectionId = async (payload: GetDriversByConnectionIdPayload) => {
    const r = await fetch(
        `${BASE_URL}/drivers?connectionId=${payload.connection_id}`,
        {
            method: 'GET',
            headers: authorizedRoutesHeaders(payload.token),
        },
    ).catch((e) => e.response);
	if (!r?.ok) return [];
	return r.json();
};
//get driver by id
export const getDriverById = async (payload: GetDriverByIdPayload) => {
    const r = await fetch(
        `${BASE_URL}/drivers/${payload.id}`,
        {
            method: 'GET',
            headers: authorizedRoutesHeaders(payload.token),
        },
    ).catch((e) => e.response);
	if (!r?.ok) return;
	return r.json();
};
export default {
    getDriversByConnectionId,
    getDriverById
};