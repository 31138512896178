export default [
    {
        name: 'Home',
        path: '/',
        component: () => import('../views/home/page.vue'),
    },
    {
        name: 'Users',
        path: '/users',
        component: () => import('../views/users/page.vue'),
        props: {
            parent: 'team',
            icon: 'group',
        },
    },
    {
        name: 'Connections',
        path: '/connections',
        component: () => import('../views/connections/page.vue'),
        props: {
            parent: 'team',
            icon: 'link',
            hide_children: true,
        },
        children: [
            {
                path: 'active',
                component: () => import('../views/connections/active/page.vue'),
            },
            {
                path: 'invites',
                component: () => import('../views/connections/invites/page.vue'),
            },
        ],
    },
    {
        name: 'Integration',
        path: '/integration',
        component: () => import('../views/integration/page.vue'),
        props: {
            hide_children: true,
        },
        children: [
            {
                path: 'invite',
                component: () => import('../views/integration/invite/page.vue'),
            },
        ],
    },
    {
        name: 'Vehicles',
        path: '/vehicles',
        component: () => import('../views/vehicles/page.vue'),
        props: {
            parent: 'view',
            icon: 'directions_car',
            hide_children: true,
        },
        children: [
            {
                path: 'profile/:id',
                component: () => import('../views/vehicles/profile/page.vue'),
            }
        ],
    },
    {
        name: 'Map',
        path: '/map',
        component: () => import('../views/map/page.vue'),
        props: {
            parent: 'view',
            icon: 'map',
        },
    },
    {
        name: 'Videos',
        path: '/videos',
        component: () => import('../views/videos/page.vue'),
        props: {
            parent: 'view',
            icon: 'videocam',
            hide_children: true,
        },
        children: [
            {
                name: 'Video',
                path: ':rec_id',
                component: () => import('../views/videos/video/page.vue'),
            },
        ],
    },
    {
        name: 'Drivers',
        path: '/drivers',
        component: () => import('../views/drivers/page.vue'),
        props: {
            parent: 'view',
            icon: 'driver',
            hide_children: true,
            customIcon: true
        },
        children: [
            {
                path: 'profile/:id',
                component: () => import('../views/drivers/profile/page.vue'),
            }
        ],
    },
    {
        name: 'Profile',
        path: '/profile',
        component: () => import('../views/profile/page.vue'),
        props: {
            manual: true,
        },
        children: [
            {
                name: 'Preferences',
                path: 'preferences',
                component: () => import('../views/profile/preferences/page.vue'),
            },
            {
                name: 'User Details',
                path: 'user-details',
                component: () => import('../views/profile/user-details/page.vue'),
            },
        ],
    },
    {
        name: 'Policies',
        path: '/policies',
        component: () => import('../views/policies/page.vue'),
        props: {
            parent: 'view',
            icon: 'local_police',
        },
    },
    {
        name: 'Analytics',
        path: '/analytics',
        component: () => import('../views/analytics/page.vue'),
        props: {
            parent: 'analytics',
        },
        children: [
            {
                name: 'Data Analytics',
                path: 'data-analytics',
                props: {
                    icon: 'pie_chart',
                },
                component: () => import('../views/analytics/data-analytics/page.vue'),
            },
            {
                name: 'Driver Risk',
                path: 'driver-risk',
                props: {
                    icon: 'driver-risk',
                    customIcon: true,
                },
                component: () => import('../views/analytics/driver-risk/page.vue'),
            },
            {
                name: 'Incident Report',
                path: 'incident-report',
                props: {
                    icon: 'warning',
                },
                component: () => import('../views/analytics/incident-report/page.vue'),
            },
            {
                name: 'Accident Report',
                path: 'accident-report',
                props: {
                    icon: 'accident',
                    customIcon: true,
                },
                component: () => import('../views/analytics/accident-report/page.vue'),
            },
        ],
    },
    {
        name: 'Coaching',
        path: '/coaching',
        component: () => import('../views/coaching/page.vue'),
        props: {
            parent: 'coaching',
        },
        children: [
            {
                name: 'Coaching Report',
                path: 'coaching-report',
                props: {
                    icon: 'feedback',
                    disabled: true,
                },
                component: () => import('../views/coaching/coaching-report/page.vue'),
            },
            {
                name: 'Sharing',
                path: 'sharing',
                props: {
                    icon: 'share',
                },
                component: () => import('../views/coaching/sharing/page.vue'),
            },
            {
                name: 'Coaching Messages',
                path: 'coaching-messages',
                props: {
                    icon: 'support_agent',
                    disabled: true,
                },
                component: () => import('../views/coaching/coaching-messages/page.vue'),
            },
        ],
    },
    {
        name: 'Data Engine',
        path: '/data-engine',
        component: () => import('../views/data-engine/page.vue'),
        props: {
            parent: 'data-engine',
        },
        children: [
            {
                name: 'Validation Report',
                path: 'validation-report',
                props: {
                    icon: 'checklist',
                },
                component: () => import('../views/data-engine/validation-report/page.vue'),
            },
        ],
    },
    {
        name: 'Incident Type',
        path: '/admin/incident-type',
        component: () => import('../views/admin/incident-type/page.vue'),
        props: {
            parent: 'admin',
            icon: 'minor_crash',
        },
    },
]